import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EventList from "./Events";
import { Col, Container, Row } from "react-bootstrap";
import RegionalEngineerLink from "./RegionalEngineerLink";
import { EventQuery } from "../../../fragments/NodeEventFields";
import { RegionalEngineerFields } from "../../../fragments/NodeRegionalEngineerFields";
import { Type } from "../Typography";
import styled from "styled-components";

const RELinkCol = styled(Col)`
  position: relative;
  & > div {
    display: block;
    max-height: 100%;
  }
`;

const EventsMainPage = () => {
  const data: {
    events: { edges: EventQuery[] };
    engineers: { edges: { node: RegionalEngineerFields }[] };
  } = useStaticQuery(graphql`
    query {
      events: allContentfulEvents(
        sort: { fields: startDate, order: ASC }
        filter: { title: { ne: "schema" } }
      ) {
        edges {
          node {
            ...NodeEventFields
          }
        }
      }
      engineers: allContentfulEngineer {
        edges {
          node {
            ...NodeRegionalEngineerFields
            largerImage {
              title
              file {
                url
                contentType
              }
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  `);
  // console.log("data", data);
  const orderedEngineers = data?.engineers?.edges.sort((a, b) => {
    if (a?.node?.region < b?.node?.region) {
      return -1;
    } else if (a?.node?.region > b?.node?.region) {
      return 1;
    }
  });
  // console.log("orderedEngineers", orderedEngineers);
  return (
    <>
      <Container style={{ paddingTop: "1rem" }}>
        <Row style={{justifyContent: "center"}}>
          {orderedEngineers.map((engineer) => {
            // console.log("engineer", engineer);
            return (
              <RELinkCol xl={3} lg={4} md={5} xs={6} className="reg-eng-link" key={`${engineer?.node?.name} link`}>
                <RegionalEngineerLink
                  region={engineer?.node?.region}
                  image={engineer?.node?.largerImage}
                  link={engineer?.node?.name}
                />
              </RELinkCol>
            );
          })}
        </Row>
      </Container>
      <Container style={{ marginTop: "2rem" }}>
        <Row>
          <Col
            style={{
              position: "relative",
              top: "10px",
              display: "flex",
              justifyContent: "center"
            }}>
            <Type.SubHeader2>All DIPRA Events</Type.SubHeader2>
          </Col>
        </Row>
      </Container>
      <EventList data={data} />
    </>
  );
};

export default EventsMainPage;
