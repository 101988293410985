import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";
import { colors } from "../../../vars/palette";
import { Type } from "../Typography";

const RELinkWrap = styled(Container)`
  text-decoration: none;
  margin-bottom: 1rem;
  position: relative;

  .col {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .image-section {
    background: ${colors.blue.hex};
    margin-bottom: 5px;
  }
  .text-section {
    padding: 1rem;
  }
  .gatsby-image-wrapper img {
    width: 142px;
    height: 126px;
  }
`;

const RELinkOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:hover {
    background: rgba(0, 0, 0, 0.45);
  }
`;

const RegionalEngineerLink = ({ image, link, region }) => {
  // console.log("region", region);
  const adjustedTitle = region.split(": ");
  // console.log("adjustedTitle", adjustedTitle);
  const regionDisplayTitle = region.includes("Canada")
    ? adjustedTitle[1]
    : `${adjustedTitle[1]} States`;

  const regionalMapColors = {
    "Region 5: Northern": "#66cfe8",
    "Region 3: Southern": "#84c447",
    "Region 1: Western": "#00afd8",
    "Region 2: Southwestern": "#cad451",
    "Region 6: Canada": "#1f3e4f",
    "Region 4: Southeastern": "#001743",
    "Region 7: Northeastern": "#84c447"
  };

  return (
    <WmkLink
      to={`/news/upcoming-events/${adjustedTitle[1].toLowerCase()}`}
      style={{ textDecoration: "none", display: "block", position: "relative" }}>
      <RELinkWrap>
        <Row>
          <Col className="image-section">
            <WmkImage image={new Img(image)} />
          </Col>
        </Row>
        <Row>
          <Col
            className="text-section"
            style={{ background: regionalMapColors[region] }}>
            <Type.PageSubhead style={{ color: "white" }}>
              {adjustedTitle[1]}
            </Type.PageSubhead>
          </Col>
        </Row>
      </RELinkWrap>
      <RELinkOverlay />
    </WmkLink>
  );
};

export default RegionalEngineerLink;
